
  import { Component, Watch } from 'vue-property-decorator'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import IdentifierStepper from '@/components/forms/inspection/steppers/IdentifierStepper.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import { isValidNumber } from '@/utils/general'

@Component({
  methods: { isValidNumber },
  components: { RatingCell, IdentifierStepper, BasePanel },
})
  export default class IdentifierPanel extends BasePanel {
  total = null

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  @Watch('inspection.metadata', { immediate: true })
  onMetadataChange (metadata: any) {
    if (!metadata) return

    const { categoryName } = this
    const category = metadata.findCategoryByName(categoryName)

    if (!category) return

    this.total = category.total
  }

  handlerInput (value) {
    if (!value?.category) return

    this.$emit('input', value)
  }
  }
