
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import BaseCardInspection from '@/components/forms/inspection/base/BaseCardInspection.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import { isValidNumber } from '@/utils/general'

@Component({
  components: { RatingCell, BaseCardInspection },
})
  export default class ButtonCard extends BaseCardInspection {
  @Prop({ type: Boolean, default: false }) displayValidationWord!: boolean
  total = null

  supervisorScore = null
  inspectorScore = null
  displayInspectorScore = false
  displaySupervisorScore = false
  isMaintenanceScore = false

  get isMetadata () {
    const { inspection } = this
    return Boolean(inspection?.metadata)
  }

  @Watch('inspection.metadata', { immediate: true, deep: true })
  onMetadataChange (metadata: any) {
    if (!metadata) return

    const { categoryName } = this
    const category = metadata.findCategoryByName(categoryName)

    if (!category) return

    this.total = category.total

    this.inspectorScore = metadata?.findCategoryByName(categoryName)?.inspectorValidated && metadata?.findCategoryByName(categoryName)?.score

    if (this.inspectorScore && this.inspectorScore !== -1) {
      this.displayInspectorScore = isValidNumber(this.inspectorScore)
    }
    const isValidated = this.inspection?.supervisorMetadata?.findCategoryByName(categoryName)?.validated
    if (this.inspectorScore === null && !isValidated) {
      this.isMaintenanceScore = true
    }
  }

  @Watch('inspection.supervisorMetadata', { immediate: true, deep: true })
  onSupervisorMetadataChange (supervisorMetadata: any) {
    if (!supervisorMetadata) return

    const { categoryName } = this
    const category = supervisorMetadata.findCategoryByName(categoryName)

    if (!category) return

    this.supervisorScore = supervisorMetadata?.findCategoryByName(categoryName)?.score
    if (this.supervisorScore !== -1) {
      this.displaySupervisorScore = isValidNumber(this.supervisorScore) && this.displayRating && this.supervisor && category?.validated
    }
  }

  get displayRating () {
    const { categoryName, inspection } = this

    const score = inspection?.metadata?.findCategoryByName(categoryName)?.score

    return score && score >= 0
  }

  get canValidated () {
    const { supervisor, categoryName, inspection, categoryError } = this

    if (categoryError) {
      return true
    }

    if (!supervisor) {
      return null
    }

    return inspection.metadata?.findCategoryByName(categoryName)?.inspectorValidated === true
  }

  get disabledCard () {
    const { disabledOpen, disabled, color, isValidatedSupervisor, supervisor } = this

    return disabledOpen || disabled || (color === 'greyShadow') || (isValidatedSupervisor && !supervisor)
  }

  get supervisorValidated () {
    const { categoryName, inspection, supervisor } = this

    if (!supervisor) return null

    return !inspection?.supervisorMetadata?.findCategoryByName(categoryName)?.validated
  }

  get color () {
    const { canValidated, supervisor, categoryError, disabledOpen } = this
    if (disabledOpen) {
      return 'greyShadow'
    }

    if (categoryError) {
      return 'red'
    }

    if (!supervisor) {
      return 'primary'
    }

    return canValidated ? 'primary' : 'greyShadow'
  }

  get isValidatedInspector () {
    const { inspection, categoryName } = this

    return inspection?.metadata?.findCategoryByName(categoryName)?.inspectorValidated
  }

  get isValidatedSupervisor () {
    const { inspection, categoryName } = this

    return inspection?.supervisorMetadata?.findCategoryByName(categoryName)?.validated
  }

  get displayCheckInspector () {
    const { displayValidationWord, isValidatedInspector, supervisor } = this

    return displayValidationWord && isValidatedInspector && !supervisor
  }

  get isToValidatedInspector () {
    const { isValidatedInspector, supervisor } = this

    return !isValidatedInspector && !supervisor
  }

  get isToValidatedSupervisor () {
    const { isValidatedSupervisor, supervisor } = this

    return !isValidatedSupervisor && supervisor
  }

  get displayCheckSupervisor () {
    const { displayValidationWord, isValidatedSupervisor, supervisor } = this

    return displayValidationWord && isValidatedSupervisor && supervisor
  }
  }
