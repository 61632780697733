
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import EngineContent from '@/components/forms/inspection/steppers/content/EngineContent.vue'
  import RefrigerantContent from '@/components/forms/inspection/steppers/content/RefrigerantContent.vue'
  import BatteryContent from '@/components/forms/inspection/steppers/content/BatteryContent.vue'
  import CarExhaustContent from '@/components/forms/inspection/steppers/content/CarExhaustContent.vue'
  import BrakesContent from '@/components/forms/inspection/steppers/content/BrakesContent.vue'
  import CarDirectionContent from '@/components/forms/inspection/steppers/content/CarDirectionContent.vue'
  import CarShockAbsorberContent from '@/components/forms/inspection/steppers/content/CarShockAbsorberContent.vue'
  import ClutchContent from '@/components/forms/inspection/steppers/content/ClutchContent.vue'
  import TransmissionContent from '@/components/forms/inspection/steppers/content/TransmissionContent.vue'
  import TractionContent from '@/components/forms/inspection/steppers/content/TractionContent.vue'
  import DashboardContent from '@/components/forms/inspection/steppers/content/electric/DashboardContent.vue'
  import ScannerContent from '@/components/forms/inspection/steppers/content/electric/ScannerContent.vue'
  import ScannerSupervisorContent
    from '@/components/forms/inspection/supervisor/content/electric/ScannerSupervisorContent.vue'

@Component({
  components: {
    ScannerSupervisorContent,
    ScannerContent,
    DashboardContent,
    TractionContent,
    TransmissionContent,
    ClutchContent,
    CarShockAbsorberContent,
    CarDirectionContent,
    BrakesContent,
    CarExhaustContent,
    BatteryContent,
    RefrigerantContent,
    EngineContent,
    RatingCell,
    GFiles,
    GFormSlot,
    BasePanel,
  },
})
  export default class ElectricPanel extends BaseStepper {
  formData = {
    dashboard: {
      cost: 0,
    },
    scanner: {
      cost: 0,
    },
  }

  componentKeys = ['dashboard', 'scanner']

  traction = null

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get scanner () {
    return this.findComponentBySlug(this.components, 'scanner')
  }

  get dashboard () {
    return this.findComponentBySlug(this.components, 'vehicle_dashboard')
  }

  @Watch('step', { immediate: true })
  async onStepChange () {
    const {
      displayRating,
      categoryName,
      category,
      categoryQualificationConfig,
      inspection,
      progress,
    } = this
    const total = this.getTotalCost(this.formData)
    if (displayRating) {
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total })
    } else {
      await this.updateCategoryProgress(inspection, categoryName, progress, 9)
    }
  }

  get progress () {
    const { step, componentKeys } = this

    if (step > 1) {
      return ((step - 1) / componentKeys.length) * 100
    }

    return 0
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  get displayRating () {
    const { categoryName, inspection } = this

    const score = inspection?.metadata?.findCategoryByName(categoryName)?.score

    return score && score >= 0
  }

  async electricPanelDone (event) {
    this.$emit('update:inspection', event)
    if (this.step > this.componentKeys.length) {
      const { categoryName, category, categoryQualificationConfig } = this
      const total = this.getTotalCost(this.formData)
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total })
      this.$emit('inspection-category-done')
    }
  }
  }
