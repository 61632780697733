
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import { Debounce } from '@/utils/decorators'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { isArray } from 'class-validator'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import GCostField from '@/components/core/input/GCostField.vue'

@Component({
  components: { GCostField, RatingCell, GFiles, GFormSlot, BasePanel },
})
  export default class OpticPanel extends BaseStepper {
  componentKeys = [
    'leftHeadlight', 'rightHeadLight', 'leftFogLamp', 'rightFogLamp', 'leftRearLight', 'rightRearLight',
  ]

  fieldsKeys = ['status']
  validation = false
  loadingSend = false
  cost = {
    leftHeadlight: false,
    rightHeadLight: false,
    leftFogLamp: false,
    rightFogLamp: false,
    leftRearLight: false,
    rightRearLight: false,
  }

  formData = {
    leftHeadlight: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    rightHeadLight: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    leftFogLamp: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    rightFogLamp: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    leftRearLight: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    rightRearLight: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
  }

  errorIdentifierMessage = {
    leftHeadlight: {
      provisional: '',
      status: '',
    },
    rightHeadLight: {
      provisional: '',
      status: '',
    },
    leftFogLamp: {
      provisional: '',
      status: '',
    },
    rightFogLamp: {
      status: '',
      provisional: '',
    },
    leftRearLight: {
      provisional: '',
      status: '',
    },
    rightRearLight: {
      provisional: '',
      status: '',
    },
  }

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get statusItems () {
    const {
      leftHeadlight,
      rightHeadLight,
      leftFogLamp,
      rightFogLamp,
      leftRearLight,
      rightRearLight,
    } = this

    return {
      leftHeadlight: leftHeadlight?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      rightHeadLight: rightHeadLight?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      leftFogLamp: leftFogLamp?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      rightFogLamp: rightFogLamp?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      leftRearLight: leftRearLight?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      rightRearLight: rightRearLight?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
    }
  }

  get statusAnswer () {
    const {
      leftHeadlight,
      rightHeadLight,
      leftFogLamp,
      rightFogLamp,
      leftRearLight,
      rightRearLight,
    } = this

    return {
      leftHeadlight: this.getQuestionOptions(leftHeadlight, 1)?.find(item => item.score === 0),
      rightHeadLight: this.getQuestionOptions(rightHeadLight, 1)?.find(item => item.score === 0),
      leftFogLamp: this.getQuestionOptions(leftFogLamp, 1)?.find(item => item.score === 0),
      rightFogLamp: this.getQuestionOptions(rightFogLamp, 1)?.find(item => item.score === 0),
      leftRearLight: this.getQuestionOptions(leftRearLight, 1)?.find(item => item.score === 0),
      rightRearLight: this.getQuestionOptions(rightRearLight, 1)?.find(item => item.score === 0),
    }
  }

  get rightRearLight () {
    return this.findComponentBySlug(this.components, 'right_rear_light')
  }

  get leftRearLight () {
    return this.findComponentBySlug(this.components, 'left_rear_light')
  }

  get rightFogLamp () {
    return this.findComponentBySlug(this.components, 'right_fog_lamp')
  }

  get leftFogLamp () {
    return this.findComponentBySlug(this.components, 'left_fog_lamp')
  }

  get leftHeadlight () {
    return this.findComponentBySlug(this.components, 'left_head_light')
  }

  get rightHeadLight () {
    return this.findComponentBySlug(this.components, 'right_head_light')
  }

  setErrorMessage (key, field) {
    if (field === 'status' && (this.formData[key][field].provisional === null || this.formData[key][field].provisional === undefined)) {
      this.errorIdentifierMessage[key].provisional = 'Este campo es requerido'
    } else if (field === 'status' && (this.formData[key][field].provisional !== null || this.formData[key][field].provisional !== undefined)) {
      this.errorIdentifierMessage[key].provisional = ''
    }

    this.errorIdentifierMessage[key][field] = 'Este campo es requerido'
  }

  validateFields (data, key) {
    const fields = ['status']
    let isValid = false

    const handleValidation = field => {
      const isArr = isArray(data[field]?.answer)
      const isNum = !isNaN(data[field]?.answer)

      if ((isNum && !data[field]?.answer) || (isArr && !data[field]?.answer.length)) {
        this.setErrorMessage(key, field)
        return true
      }
      this.errorIdentifierMessage[key][field] = ''
      return false
    }

    for (const field of fields) {
      const isProvisional = 'provisional' in data[field]
      const validNotProvisional = isProvisional === false && handleValidation(field)
      const validProvisional = (isProvisional === true && !data[field]?.provisional) && handleValidation(field)

      if (data[field]?.provisional) {
        this.validation = true
        this.formData[key].status.answer = []
      }

      if (validNotProvisional || validProvisional) {
        isValid = true
        break
      } else {
        this.errorIdentifierMessage[key][field] = ''
      }
    }

    return isValid
  }

  async send (component, data, field = null) {
    if (this.validateFields(data, field)) {
      return
    }
    this.loadingSend = true
    const { step, photoProperties, inspection, cost } = this

    await this.sendComponentOptics(component, this.formData[field], photoProperties, inspection.id)

    if (!cost[field] && data?.photo?.length) {
      await this.deleteFiles(data.photo)
    }
    this.step = step + 1

    if (this.step > this.componentKeys.length) {
      const { categoryName, category, categoryQualificationConfig } = this
      const total = this.getTotalCost(this.formData)
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total })
      this.$emit('inspection-category-done')
    }
    this.loadingSend = false
  }

  get allComponents () {
    const {
      leftHeadlight,
      rightHeadLight,
      leftFogLamp,
      rightFogLamp,
      leftRearLight,
      rightRearLight,
      selfPanel,
    } = this

    return {
      leftHeadlight,
      rightHeadLight,
      leftFogLamp,
      rightFogLamp,
      leftRearLight,
      rightRearLight,
      selfPanel,
    }
  }

  @Watch('allComponents', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    const { idProcess, inspection, componentKeys, displayQualification } = this
    this.step = 1
    const handleComponentChange = async (component, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        if (!matchingInspectedComponent.findInspectionParameterByOrder(1)?.assessment?.score) {
          this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(1)?.assessment?.id
          this.formData[key].status.answer = null
        } else {
          this.formData[key].status.provisional = false
          this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(1).map(item => item.assessment.id)
        }

        this.formData[key].cost = matchingInspectedComponent.cost
        if (matchingInspectedComponent.cost) {
          this.cost[key] = true
        }
      }
    }

    for (const key of componentKeys) {
      await handleComponentChange(val[key], key)
    }

    if (((this.formData.rightRearLight.status.provisional === false && this.formData.rightRearLight.status.answer) || (this.formData.rightRearLight.status.provisional)) && !displayQualification) {
      this.step = this.step + 1
    }
  }

  @Watch('step', { immediate: true })
  async onStepChange () {
    const {
      displayRating,
      inspection,
      categoryName,
      progress,
      category,
      categoryQualificationConfig,
    } = this
    const total = this.getTotalCost(this.formData)
    if (!displayRating) {
      await this.updateCategoryProgress(inspection, categoryName, progress, 5)
    } else {
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total })
    }
  }

  get progress () {
    const { step, componentKeys } = this

    if (step > 1) {
      return ((step - 1) / componentKeys.length) * 100
    }

    return 0
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  @Watch('formData', { immediate: true, deep: true })
  onFormDataChange (val) {
    const { fieldsKeys, componentKeys, validation } = this

    if (validation) {
      this.validation = false
      return
    }
    componentKeys.forEach(key => {
      const data = val[key]
      this.cost[key] = this.isAnyFieldValid(fieldsKeys, data, this[key])

      this.initiateErrorMessage(key)
      if (!this.cost[key] && this.formData[key].cost) {
        this.formData[key].cost = 0
      }
    })
  }

  initiateErrorMessage (key) {
    this.errorIdentifierMessage[key].status = ''
    this.errorIdentifierMessage[key].provisional = ''
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  get displayRating () {
    const { categoryName, inspection } = this

    const score = inspection?.metadata?.findCategoryByName(categoryName)?.score

    return score && score >= 0
  }
  }
