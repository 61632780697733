
  import { Component, Watch } from 'vue-property-decorator'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import { Debounce } from '@/utils/decorators'
  import { mapActions } from 'vuex'
  import { NotificationContent } from '@/store/notification/state'

@Component({
  methods: {
    ...mapActions('notification', ['loadNotification']),
  },
})
  export default class inspectionComment extends BasePanel {
  loadNotification!: (obj: NotificationContent) => Promise<void>;
  comment = ''
  errorMessageComment = ''

  // Methods
  @Debounce()
  async sendComment () {
    if (this.comment?.length > 256) {
      this.errorMessageComment = 'El comentario debe no debe tener mas de 256 caracteres'
      return
    }

    if (!this.supervisor) {
      const metadata = this.inspection.metadata
      metadata.commentInspector = this.comment

      await this.pushData({
        model: 'Inspection',
        fields: {
          id: this.inspection.id,
          metadata,
        },
      })
    } else {
      const supervisorMetadata = this.inspection?.supervisorMetadata || this.inspection?.metadata
      supervisorMetadata.comment = this.comment

      await this.pushData({
        model: 'Inspection',
        fields: {
          id: this.inspection.id,
          supervisor_metadata: supervisorMetadata,
        },
      })
    }
    await this.loadNotification({
      // @ts-ignore catch must be any or unknown interface
      message: `Comentario Guardado`,
      notificationType: 'success',
    })
  }

  // Getters

  // Watchers
  @Watch('inspection', { immediate: true, deep: true })
  onInspectionChange (val) {
    if (!val) return

    if (!this.supervisor) {
      this.comment = val.metadata?.commentInspector
    } else {
      this.comment = val.supervisorMetadata?.comment
    }
  }
  }
