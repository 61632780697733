
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import { Debounce } from '@/utils/decorators'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { isArray } from 'class-validator'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import GCostField from '@/components/core/input/GCostField.vue'

@Component({
  components: { GCostField, RatingCell, GFiles, BasePanel, GFormSlot },
})
  export default class BodyWorkPanel extends BaseStepper {
  componentKeys = [
    'frontBumper', 'carHood', 'leftFrontFender', 'leftDoor', 'leftRocker', 'leftRearDoor', 'leftRearFender', 'trunkTailgate', 'rearBumper',
    'rightRearFender', 'rightRearDoor', 'rightRocker', 'rightDoor', 'rightFrontFender', 'carRoof', 'chassis',
  ]

  fieldsKeys = ['original', 'paintOriginal', 'status']
  validation = false
  loadingSend = false
  cost = {
    frontBumper: false,
    carHood: false,
    leftFrontFender: false,
    leftDoor: false,
    leftRocker: false,
    leftRearDoor: false,
    leftRearFender: false,
    trunkTailgate: false,
    rearBumper: false,
    rightRearFender: false,
    rightRearDoor: false,
    rightRocker: false,
    rightDoor: false,
    rightFrontFender: false,
    carRoof: false,
    chassis: false,
  }

  formData = {
    frontBumper: {
      original: {
        answer: null,
      },
      paintOriginal: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
    },
    carHood: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    leftFrontFender: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    leftDoor: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    leftRocker: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    leftRearDoor: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    leftRearFender: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    trunkTailgate: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    rearBumper: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    rightRearFender: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    rightRearDoor: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    rightRocker: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    rightDoor: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    rightFrontFender: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    carRoof: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
    chassis: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      photo: null,
    },
  }

  errorIdentifierMessage = {
    frontBumper: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
    },
    carHood: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
    },
    leftFrontFender: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
    },
    leftDoor: {
      original: '',
      paintOriginal: '',
      status: '',
      provisional: '',
    },
    leftRocker: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
    },
    leftRearDoor: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
    },
    leftRearFender: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
    },
    trunkTailgate: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
    },
    rearBumper: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
    },
    rightRearFender: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
    },
    rightRearDoor: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
    },
    rightRocker: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
    },
    rightDoor: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
    },
    rightFrontFender: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
    },
    carRoof: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
    },
    chassis: {
      original: '',
      provisional: '',
      status: '',
      paintOriginal: '',
    },
  }

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get statusItems () {
    const {
      frontBumper,
      carHood,
      leftFrontFender,
      leftDoor,
      leftRocker,
      leftRearDoor,
      leftRearFender,
      trunkTailgate,
      rearBumper,
      rightRearFender,
      rightRearDoor,
      rightRocker,
      rightDoor,
      rightFrontFender,
      carRoof,
      chassis,
    } = this

    return {
      frontBumper: frontBumper.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      carHood: carHood.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      leftFrontFender: leftFrontFender.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      leftDoor: leftDoor.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      leftRocker: leftRocker.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      leftRearDoor: leftRearDoor.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      leftRearFender: leftRearFender.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      trunkTailgate: trunkTailgate.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      rearBumper: rearBumper.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      rightRearFender: rightRearFender.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      rightRearDoor: rightRearDoor.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      rightRocker: rightRocker.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      rightDoor: rightDoor.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      rightFrontFender: rightFrontFender.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      carRoof: carRoof.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      chassis: chassis.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
    }
  }

  get statusAnswer () {
    const {
      frontBumper,
      carHood,
      leftFrontFender,
      leftDoor,
      leftRocker,
      leftRearDoor,
      leftRearFender,
      trunkTailgate,
      rearBumper,
      rightRearFender,
      rightRearDoor,
      rightRocker,
      rightDoor,
      rightFrontFender,
      carRoof,
      chassis,
    } = this
    return {
      frontBumper: this.getQuestionOptions(frontBumper, 3).find(item => item.score === 0),
      carHood: this.getQuestionOptions(carHood, 3).find(item => item.score === 0),
      leftFrontFender: this.getQuestionOptions(leftFrontFender, 3).find(item => item.score === 0),
      leftDoor: this.getQuestionOptions(leftDoor, 3).find(item => item.score === 0),
      leftRocker: this.getQuestionOptions(leftRocker, 3).find(item => item.score === 0),
      leftRearDoor: this.getQuestionOptions(leftRearDoor, 3).find(item => item.score === 0),
      leftRearFender: this.getQuestionOptions(leftRearFender, 3).find(item => item.score === 0),
      trunkTailgate: this.getQuestionOptions(trunkTailgate, 3).find(item => item.score === 0),
      rearBumper: this.getQuestionOptions(rearBumper, 3).find(item => item.score === 0),
      rightRearFender: this.getQuestionOptions(rightRearFender, 3).find(item => item.score === 0),
      rightRearDoor: this.getQuestionOptions(rightRearDoor, 3).find(item => item.score === 0),
      rightRocker: this.getQuestionOptions(rightRocker, 3).find(item => item.score === 0),
      rightDoor: this.getQuestionOptions(rightDoor, 3).find(item => item.score === 0),
      rightFrontFender: this.getQuestionOptions(rightFrontFender, 3).find(item => item.score === 0),
      carRoof: this.getQuestionOptions(carRoof, 3).find(item => item.score === 0),
      chassis: this.getQuestionOptions(chassis, 3).find(item => item.score === 0),
    }
  }

  get chassis () {
    return this.findComponentBySlug(this.components, 'chassis')
  }

  get carRoof () {
    return this.findComponentBySlug(this.components, 'car_roof')
  }

  get rightFrontFender () {
    return this.findComponentBySlug(this.components, 'right_front_fender')
  }

  get rightDoor () {
    return this.findComponentBySlug(this.components, 'right_door')
  }

  get rightRocker () {
    return this.findComponentBySlug(this.components, 'right_rocker')
  }

  get rightRearDoor () {
    return this.findComponentBySlug(this.components, 'right_rear_door')
  }

  get rightRearFender () {
    return this.findComponentBySlug(this.components, 'right_rear_fender')
  }

  get rearBumper () {
    return this.findComponentBySlug(this.components, 'rear_bumper')
  }

  get trunkTailgate () {
    return this.findComponentBySlug(this.components, 'trunk_tailgate')
  }

  get leftRearFender () {
    return this.findComponentBySlug(this.components, 'left_rear_fender')
  }

  get leftRearDoor () {
    return this.findComponentBySlug(this.components, 'left_rear_door')
  }

  get leftRocker () {
    return this.findComponentBySlug(this.components, 'left_rocker')
  }

  get leftDoor () {
    return this.findComponentBySlug(this.components, 'left_door')
  }

  get leftFrontFender () {
    return this.findComponentBySlug(this.components, 'left_front_fender')
  }

  get frontBumper () {
    return this.findComponentBySlug(this.components, 'front_bumper')
  }

  get carHood () {
    return this.findComponentBySlug(this.components, 'car_hood')
  }

  setErrorMessage (key, field) {
    if (field === 'status' && (this.formData[key][field].provisional === null || this.formData[key][field].provisional === undefined)) {
      this.errorIdentifierMessage[key].provisional = 'Este campo es requerido'
    } else if (field === 'status' && (this.formData[key][field].provisional !== null || this.formData[key][field].provisional !== undefined)) {
      this.errorIdentifierMessage[key].provisional = ''
    }

    this.errorIdentifierMessage[key][field] = 'Este campo es requerido'
  }

  validateFields (data, key) {
    const fields = ['original', 'status', 'paintOriginal']
    let isValid = false

    const handleValidation = field => {
      const isArr = isArray(data[field]?.answer)
      const isNum = !isNaN(data[field]?.answer)

      if ((isNum && !data[field]?.answer) || (isArr && !data[field]?.answer.length)) {
        this.setErrorMessage(key, field)
        return true
      }
      this.errorIdentifierMessage[key][field] = ''
      return false
    }

    for (const field of fields) {
      const isProvisional = 'provisional' in data[field]
      const validNotProvisional = isProvisional === false && handleValidation(field)
      const validProvisional = (isProvisional === true && !data[field]?.provisional) && handleValidation(field)

      if (data[field]?.provisional && this.formData[key].status?.answer?.length) {
        this.validation = true
        this.formData[key].status.answer = []
      }

      if (validNotProvisional || validProvisional) {
        isValid = true
        break
      } else {
        this.errorIdentifierMessage[key][field] = ''
      }
    }

    return isValid
  }

  async send (component, data, field = null) {
    if (this.validateFields(data, field)) {
      return
    }
    this.loadingSend = true
    const { step, photoProperties, inspection } = this

    await this.sendComponentPiece(component, this.formData[field], photoProperties, inspection.id)

    if (!data.cost && data?.photo?.length) {
      await this.deleteFiles(data.photo)
    }
    this.step = step + 1

    if (this.step > this.componentKeys.length) {
      const { categoryName, category, categoryQualificationConfig } = this
      const total = this.getTotalCost(this.formData)
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total })
      this.$emit('inspection-category-done')
    }
    this.loadingSend = false
  }

  get allComponents () {
    const {
      frontBumper,
      carHood,
      leftFrontFender,
      leftDoor,
      leftRocker,
      leftRearDoor,
      leftRearFender,
      trunkTailgate,
      rearBumper,
      rightRearFender,
      rightRearDoor,
      rightRocker,
      rightDoor,
      rightFrontFender,
      carRoof,
      chassis,
      selfPanel,
    } = this

    return {
      frontBumper,
      carHood,
      leftFrontFender,
      leftDoor,
      leftRocker,
      leftRearDoor,
      leftRearFender,
      trunkTailgate,
      rearBumper,
      rightRearFender,
      rightRearDoor,
      rightRocker,
      rightDoor,
      rightFrontFender,
      carRoof,
      chassis,
      selfPanel,
    }
  }

  @Watch('allComponents', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    const {
      idProcess,
      inspection,
      componentKeys,
    } = this

    this.step = 1

    const handleComponentChange = async (component, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        if (this.formData[key].paintOriginal) {
          this.formData[key].paintOriginal.answer = matchingInspectedComponent.findInspectionParameterByOrder(2)?.assessment?.id
        }
        this.formData[key].original.answer = matchingInspectedComponent.findInspectionParameterByOrder(1).assessment.id

        if (!matchingInspectedComponent.findInspectionParameterByOrder(3)?.assessment?.score) {
          this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(3)?.assessment?.id
          this.formData[key].status.answer = null
        } else {
          this.formData[key].status.provisional = false
          this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(3).map(item => item.assessment.id)
        }

        this.formData[key].cost = matchingInspectedComponent.cost
      }
    }

    for (const key of componentKeys) {
      await handleComponentChange(val[key], key)
    }
  }

  @Watch('step', { immediate: true })
  async onStepChange () {
    const {
      displayRating,
      inspection,
      categoryName,
      progress,
      category,
      categoryQualificationConfig,
    } = this
    const total = this.getTotalCost(this.formData)

    if (!displayRating) {
      await this.updateCategoryProgress(inspection, categoryName, progress, 4)
    } else if (displayRating) {
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total })
    }
  }

  get progress () {
    const { step, componentKeys } = this

    if (step > 1) {
      return ((step - 1) / componentKeys.length) * 100
    }

    return 0
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  get dataToWatch () {
    const { formData, step } = this

    return {
      data: formData,
      step,
    }
  }

  @Watch('dataToWatch', { immediate: true, deep: true })
  @Debounce(500)
  onFormDataChange (val) {
    this.initiateErrorMessage()
    const { fieldsKeys, componentKeys, validation } = this
    if (validation) {
      this.validation = false
      return
    }

    const key = componentKeys[val.step - 1]
    const data = val.data[key]
    this.cost[key] = this.isAnyFieldValid(fieldsKeys, data, this[key])

    if (!this.cost[key] && this.formData[key]?.cost) {
      this.formData[key].cost = 0
    }
  }

  initiateErrorMessage () {
    const { componentKeys } = this
    componentKeys.forEach(key => {
      this.errorIdentifierMessage[key].original = ''
      this.errorIdentifierMessage[key].status = ''
      this.errorIdentifierMessage[key].provisional = ''
      if (this.errorIdentifierMessage[key]?.paintOriginal) {
        this.errorIdentifierMessage[key].paintOriginal = ''
      }
    })
  }

  findQuestionScore (questions, answer, field) {
    if (!answer) return true

    const question = questions?.find(item => item.id === answer)
    const isNotOriginal = question?.score !== 0

    if (isNotOriginal) {
      this.formData[field].paintOriginal.answer = this.getQuestionOptions(this.allComponents[field], 2).find(item => item.score !== 0).id
    }

    return !isNotOriginal
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  get viewCategory () {
    const { selfPanel, overCategory } = this

    return {
      selfPanel,
      overCategory,
    }
  }

  @Watch('viewCategory', { immediate: true, deep: true })
  onViewCategoryChange (val) {
    if (val.overCategory) {
      this.step = 1
    }
  }

  get displayRating () {
    const { categoryName, inspection } = this

    const score = inspection?.metadata?.findCategoryByName(categoryName)?.score

    return score && score >= 0
  }
  }
