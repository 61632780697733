
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import EngineContent from '@/components/forms/inspection/steppers/content/EngineContent.vue'
  import RefrigerantContent from '@/components/forms/inspection/steppers/content/RefrigerantContent.vue'
  import BatteryContent from '@/components/forms/inspection/steppers/content/BatteryContent.vue'
  import CarExhaustContent from '@/components/forms/inspection/steppers/content/CarExhaustContent.vue'
  import BrakesContent from '@/components/forms/inspection/steppers/content/BrakesContent.vue'
  import CarDirectionContent from '@/components/forms/inspection/steppers/content/CarDirectionContent.vue'
  import CarShockAbsorberContent from '@/components/forms/inspection/steppers/content/CarShockAbsorberContent.vue'
  import ClutchContent from '@/components/forms/inspection/steppers/content/ClutchContent.vue'
  import TransmissionContent from '@/components/forms/inspection/steppers/content/TransmissionContent.vue'
  import TractionContent from '@/components/forms/inspection/steppers/content/TractionContent.vue'
  import ScannerContent from '@/components/forms/inspection/steppers/content/electric/ScannerContent.vue'
  import { deepCopy } from '@/utils/general'
  import { Debounce } from '@/utils/decorators'

@Component({
  components: {
    ScannerContent,
    TractionContent,
    TransmissionContent,
    ClutchContent,
    CarShockAbsorberContent,
    CarDirectionContent,
    BrakesContent,
    CarExhaustContent,
    BatteryContent,
    RefrigerantContent,
    EngineContent,
    RatingCell,
    GFiles,
    GFormSlot,
    BasePanel,
  },
})
  export default class MechanicalPanel extends BaseStepper {
  formData = {
    engine: {
      cost: null,
    },
    refrigerant: {
      cost: null,
    },
    battery: {
      cost: null,
    },
    carExhaust: {
      cost: null,
    },
    brakes: {
      cost: null,
    },
    carDirection: {
      cost: null,
    },
    carShockAbsorber: {
      cost: null,
    },
    clutch: {
      cost: null,
    },
    transmission: {
      cost: null,
    },
    traction: {
      cost: null,
    },
  }

  componentKeys = [
    'engine', 'refrigerant', 'battery', 'carExhaust', 'carLeaks', 'brakes', 'carDirection', 'carShockAbsorber', 'clutch', 'traction',
  ]

  traction = null
  customStep = 8

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get tractionValues () {
    return this.findComponentBySlug(this.components, 'traction')
  }

  get transmission () {
    return this.findComponentBySlug(this.components, 'transmission')
  }

  get clutch () {
    return this.findComponentBySlug(this.components, 'clutch')
  }

  get carShockAbsorber () {
    return this.findComponentBySlug(this.components, 'car_shock_absorber')
  }

  get carDirection () {
    return this.findComponentBySlug(this.components, 'car_direction')
  }

  get brakes () {
    return this.findComponentBySlug(this.components, 'brakes')
  }

  get carLeaks () {
    return this.findComponentBySlug(this.components, 'car_leaks')
  }

  get carExhaust () {
    return this.findComponentBySlug(this.components, 'car_exhaust')
  }

  get battery () {
    return this.findComponentBySlug(this.components, 'battery')
  }

  get engine () {
    return this.findComponentBySlug(this.components, 'engine')
  }

  get refrigerant () {
    return this.findComponentBySlug(this.components, 'refrigerant')
  }

  get isTraction () {
    const { generation, traction } = this

    return Boolean(generation?.traction?.id) && Boolean(traction?.id)
  }

  async getAutoGeneration (id) {
    return this.fetchData({
      query: { name: 'fetch', params: { id }, model: 'Generation' },
    })
  }

  @Watch('step', { immediate: true })
  @Debounce(100)
  async onStepChange () {
    const {
      componentKeys,
      isTraction,
      displayRating,
      categoryName,
      customStep,
      inspection,
      progress,
    } = this
    const total = this.getTotalCost(this.formData)
    const keys = deepCopy(componentKeys)

    if (!isTraction) {
      keys.pop()
    }

    if (customStep !== 8) {
      keys.pop()
    }

    if (displayRating) {
      const { category, categoryQualificationConfig } = this
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total })
    } else {
      await this.updateCategoryProgress(inspection, categoryName, progress(keys), 10)
    }
  }

  progress (keys) {
    const { step } = this

    if (step > 1) {
      return ((step - 1) / keys.length) * 100
    }

    return 0
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  get isTransmissionAutomatic () {
    return this.generation?.transmission?.val === 'Automática'
  }

  get tractionAndGenerationData () {
    const { generation, tractionValues } = this
    return {
      generation,
      tractionValues,
    }
  }

  @Watch('tractionAndGenerationData', { immediate: true, deep: true })
  onTractionValues (val) {
    if (!val?.tractionValues?.id || !val?.generation?.transmission) return

    this.traction = val.tractionValues.values.find(value => value.id === val.generation?.traction?.componentValue?.id)
    if (val.generation.transmission.val === 'Automática') {
      this.customStep = 7
    }
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  get viewCategory () {
    const { selfPanel, overCategory, reset } = this

    return {
      selfPanel,
      overCategory,
      reset,
    }
  }

  get score () {
    return this.inspection.metadata.findCategoryByName(this.categoryName).score
  }

  get displayLinear () {
    const { categoryName, score } = this
    return this.isMetadata && !this.inspection.metadata.findCategoryByName(categoryName).done && score !== 100
  }

  @Watch('viewCategory', { immediate: true, deep: true })
  onViewCategoryChange (val) {
    if (val.overCategory) {
      this.step = 1
    }
  }

  get displayRating () {
    const { categoryName, inspection } = this

    const score = inspection?.metadata?.findCategoryByName(categoryName)?.score

    return score && score >= 0
  }

  async mechanicalDone (event) {
    this.$emit('update:inspection', event)
    const keys = deepCopy(this.componentKeys)

    if (!this.isTraction) {
      keys.pop()
    }

    if (this.customStep !== 8) {
      keys.pop()
    }

    if (this.step > keys.length) {
      const { categoryName, category, categoryQualificationConfig } = this
      const total = this.getTotalCost(this.formData)
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total })
      this.$emit('inspection-category-done')
    }
  }
  }
