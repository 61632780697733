
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import { Debounce } from '@/utils/decorators'
  import KeyContent from '@/components/forms/inspection/steppers/content/equipment/basic/KeyContent.vue'
  import CarExhaustContent from '@/components/forms/inspection/steppers/content/CarExhaustContent.vue'
  import { isArray } from 'class-validator'
  import _ from 'lodash'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import GCostField from '@/components/core/input/GCostField.vue'

@Component({
  components: {
    GCostField,
    GRadioButton,
    CarExhaustContent,
    KeyContent,
    RatingCell,
    GFiles,
    GFormSlot,
    BasePanel,
  },
})
  export default class ExteriorEquipmentPanel extends BaseStepper {
  equipment = {
    present: [],
    extra: [],
  }

  loadingSend = false
  exclude = ['Llantas de aleación', 'Tapas de ruedas', 'Pernos de seguridad']
  extraBackup = {}
  attributes = []
  extra = []
  fieldsKeys = ['status']
  validation = false
  cost: Record<string, any> = {}
  formDataExtra: Record<string, any> = {}
  errorIdentifierMessageExtra: Record<string, any> = {}
  extraEquipment = []
  formData: Record<string, any> = {}
  errorIdentifierMessage: Record<string, any> = {}

  ready = false
  readyExtra = false

  showIcon: Record<string, { yes: boolean, no: boolean }> = {};

  toggleIcon (component, fieldValue: string, option: 'yes' | 'no') {
    if (!this.showIcon[fieldValue]) {
      this.$set(this.showIcon, fieldValue, { yes: false, no: false })
    }

    const oppositeOption = option === 'yes' ? 'no' : 'yes'

    this.showIcon[fieldValue][option] = true
    this.showIcon[fieldValue][oppositeOption] = false
    this.formDataExtra[fieldValue].status.answer = option === 'yes'
      ? this.getQuestionOptionScoreZero(component, 1)[0].id
      : this.getQuestionOptionsScoreNotZero(component, 1)[0].id
  }

  displayField (component, field, val) {
    if (!component || !field) return false
    const { getQuestionOptionsScoreNotZero } = this
    const displayField = field === getQuestionOptionsScoreNotZero(component, 1)[0].id

    if (!displayField) {
      this.formDataExtra[val].cost = 0
    }
    return displayField
  }

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get aestheticEquipment () {
    return this.findComponentBySlug(this.components, 'aesthetic_equipment')
  }

  @Watch('step', { immediate: true })
  @Debounce(100)
  async onStepChange (val) {
    const {
      equipment: { present },
      displayRating,
      inspection,
      categoryName,
      progress,
      category,
      categoryQualificationConfig,
    } = this
    const total = present.length + 1
    if (val > total + 1) {
      return
    }
    const cost = this.getTotalCost(this.formData) + this.getTotalCost(this.formDataExtra)
    if (!displayRating) {
      await this.updateCategoryProgress(inspection, categoryName, progress(total), 14)
    } else {
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total: cost })
    }
  }

  progress (total) {
    const { step } = this

    if (step > 1) {
      return ((step - 1) / total) * 100
    }

    return 0
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  setErrorMessage (key, field) {
    if (key in this.errorIdentifierMessage) {
      this.errorIdentifierMessage[key][field] = 'Este campo es requerido'
    } else {
      this.errorIdentifierMessageExtra[key][field] = 'Este campo es requerido'
    }
  }

  validateFields (data, key) {
    const fields = ['status']
    let isValid = false

    const handleValidation = field => {
      const isArr = isArray(data[field]?.answer)
      const isNum = !isNaN(data[field]?.answer)

      if ((isNum && !data[field]?.answer) || (isArr && !data[field]?.answer.length)) {
        this.setErrorMessage(key, field)
        return true
      }
      if (key in this.errorIdentifierMessage) {
        this.errorIdentifierMessage[key][field] = ''
      } else {
        this.errorIdentifierMessageExtra[key][field] = ''
      }
      return false
    }

    for (const field of fields) {
      const isProvisional = 'provisional' in data[field]
      const validNotProvisional = isProvisional === false && handleValidation(field)
      const validProvisional = (isProvisional === true && !data[field]?.provisional) && handleValidation(field)

      if (data[field]?.provisional) {
        this.validation = true
      }

      if (validNotProvisional || validProvisional) {
        isValid = true
        break
      } else {
        if (key in this.errorIdentifierMessage) {
          this.errorIdentifierMessage[key][field] = ''
        } else {
          this.errorIdentifierMessageExtra[key][field] = ''
        }
      }
    }

    return isValid
  }

  async sendExtra () {
    const { formDataExtra, inspection, extraEquipment, attributes, extra } = this

    let invalid = false
    extraEquipment.forEach(extra => {
      if (this.validateFields(formDataExtra[extra.value], extra.value)) {
        invalid = true
      }
    })

    if (invalid) return
    this.loadingSend = true
    const promises = extraEquipment.map(equipment => this.createUpdateExtra(equipment, formDataExtra[equipment.value], this.photoProperties, inspection.id))
    await Promise.all(promises)

    const deleteAttributes = attributes.filter(att => !extra.includes(att.componentValue.id))

    if (deleteAttributes?.length) {
      await this.deleteDealAutoAttribute(deleteAttributes)
      await Promise.all(deleteAttributes.map(async ext => {
        const pos = extraEquipment.findIndex(att => att.id === ext.componentValue.id)
        if (pos !== -1) {
          const inspectionUpdated = await this.fetchData({
            query: { name: 'fetch', model: 'Inspection', params: { id: inspection.id } },
            force: true,
          })

          const matchingInspectedComponent = inspectionUpdated.inspectedComponents.find(
            ic => ic.inspectionComponent.id === extraEquipment[pos]?.inspectionComponent?.id
          )

          await this.removeData({
            model: 'InspectionQualification',
            fields: { id: matchingInspectedComponent.inspectionQualification.id },
          })
          await this.removeData({ model: 'InspectionInspectedComponent', fields: { id: matchingInspectedComponent.id } })
        }
      }))
    }
    this.step = this.step + 1
    const total = this.equipment?.present.length
    if (this.step > total) {
      const { categoryName, category, categoryQualificationConfig } = this
      const cost = this.getTotalCost(this.formData) + this.getTotalCost(this.formDataExtra)
      this.$emit('input', { categoryName, category, categoryQualificationConfig, total: cost })
      this.$emit('inspection-category-done')
    }
    this.$emit('update:inspection')
    this.loadingSend = false
  }

  async createUpdateExtra (componentValue, data, fileProperties, id) {
    const { idProcess, idEmployee, inspection, idProcessInspection } = this
    if (data.status.answer === null) return
    let resp
    const inspectionUpdated = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: inspection.id } },
      force: true,
    })

    const matchingInspectedComponent = inspectionUpdated.inspectedComponents.find(
      ic => ic.inspectionComponent.id === componentValue?.inspectionComponent?.id
    )
    if (matchingInspectedComponent) {
      resp = matchingInspectedComponent
      const fields = {
        id: resp.inspectionQualification.id,
        id_inspection_assessment: data.status.answer,
        id_inspection_parameter: componentValue?.inspectionParameter?.id,
      }
      await this.pushData({ model: 'InspectionQualification', fields })

      await this.pushData({
        model: 'InspectionInspectedComponent',
        fields: { id: matchingInspectedComponent.id, cost: data?.cost, comment: data?.comment },
      })
    } else {
      const fields = {
        id_inspection: id,
        id_inspection_component: componentValue.inspectionComponent.id,
        id_process: idProcessInspection,
        cost: data?.cost,
        comment: data?.comment,
        inspection_qualifications: {
          data: {
            id_employee: idEmployee,
            id_inspection_assessment: data.status.answer,
            id_inspection_parameter: componentValue?.inspectionParameter?.id,
          },
        },
      }
      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }

    if (this.displayField(componentValue, data[componentValue.value]?.status?.answer, componentValue.value) && data?.photo?.length) {
      await this.deleteFiles(data.photo)
    } else {
      await this.addFiles(data.photo, fileProperties, idProcess, resp)
    }

    await this.insertUpdateDealAutoAttribute(inspection.id, inspection.deal.id, componentValue, componentValue.component.id)
  }

  async send (component, data, field = null) {
    if (this.validateFields(data, field)) {
      return
    }
    this.loadingSend = true
    const { idProcess, step, photoProperties, inspection, cost, idEmployee, idProcessInspection } = this
    let resp
    const inspectionUpdated = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: inspection.id } },
      force: true,
    })

    const matchingInspectedComponent = inspectionUpdated.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      resp = matchingInspectedComponent
      await this.edit(resp, data.cost, data.comment, { statusData: data.status.answer })
    } else {
      const fields = {
        id_inspection: inspection.id,
        id_inspection_component: component.inspectionComponent.id,
        id_process: idProcessInspection,
        cost: data?.cost,
        comment: data?.comment,
        inspection_qualifications: {
          data: [
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(1)?.id,
              id_inspection_assessment: data.status.answer,
            },
          ].filter(_ => _.id_inspection_assessment),
        },
      }
      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }

    if (!cost[field] && data?.photo?.length) {
      await this.deleteFiles(data.photo)
    } else {
      await this.addFiles(data.photo, photoProperties, idProcess, resp)
    }
    this.step = step + 1

    this.$emit('update:inspection')
    this.loadingSend = false
  }

  @Watch('formData', { immediate: true, deep: true })
  onFormDataChange (val) {
    const { fieldsKeys, equipment: { present }, validation } = this

    if (!present?.length) {
      return
    }

    if (validation) {
      this.validation = false
      return
    }

    const presentKeys = Object.keys(this.formData)

    if (presentKeys.length) {
      present.forEach(key => {
        const data = val[key.value]
        this.cost[key.value] = this.isAnyFieldValid(fieldsKeys, data, key)

        this.initiateErrorMessage(key.value)
        if (!this.cost[key.value] && this.formData[key.value].cost) {
          this.formData[key.value].cost = 0
        }
      })
    }
  }

  initiateErrorMessage (key) {
    this.errorIdentifierMessage[key].status = ''
    this.errorIdentifierMessage[key].provisional = ''
  }

  get score () {
    const { inspection, category, categoryQualificationConfig, categoryName } = this
    if (!category?.id || !categoryQualificationConfig) return undefined

    const score = inspection.getCategoryScore(category.id)

    let configValue: number | undefined

    Object.entries(categoryQualificationConfig?.[categoryName]).forEach(([key, value]) => {
      const config = value as Record<string, any>
      if (score >= config.min && score <= config.max) {
        configValue = config.value
      }
    })

    return configValue
  }

  async getAutoGeneration (id) {
    return this.fetchData({
      query: { name: 'fetch', params: { id }, model: 'Generation' },
    })
  }

  get dataConsolidated () {
    const { aestheticEquipment, generation } = this

    return {
      equipment: aestheticEquipment,
      generation,
    }
  }

  @Watch('dataConsolidated', { immediate: true, deep: true })
  onComfortChange (val) {
    if (!val?.generation || !val?.equipment) return null
    const { equipment, exclude } = this

    if (equipment?.present.length && equipment.extra?.length) return null
    const { present, extra } = this.separateValues(val, 'Equipamiento exterior')

    this.equipment = {
      present: present.filter(item => !exclude.includes(item.value)),
      extra: extra.filter(item => !exclude.includes(item.value)),
    }
    this.generateFormInfo(this.equipment.present)
  }

  generateFormInfo (components) {
    const { ready } = this
    if (ready) return

    for (const field of components) {
      this.$set(this.formData, field.value, {
        status: {
          answer: null,
        },
        cost: 0,
        comment: '',
        photo: [],
      })

      this.$set(this.errorIdentifierMessage, field.value, {
        status: '',
      })

      this.$set(this.cost, field.value, false)
    }

    this.ready = true
  }

  async edit (component, cost, comment, data) {
    const { statusData } = data

    const parameter = component.findInspectionParameterByOrder(1)
    if (parameter.assessment.id !== statusData) {
      const fields = {
        id: parameter.id,
        id_inspection_assessment: statusData,
      }
      await this.pushData({ model: 'InspectionQualification', fields })
    }

    await this.pushData({
      model: 'InspectionInspectedComponent',
      fields: { id: component.id, cost, comment },
    })
  }

  get allData () {
    const { ready, selfPanel } = this

    return {
      ready, selfPanel,
    }
  }

  @Watch('allData', { immediate: true, deep: true })
  @Debounce(100)
  async onReadyChange (val) {
    if (!val.ready) return

    const { idProcess, inspection, autoClose, disabled, equipment, displayQualification } = this

    const handleComponentChange = async (component, step, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        if (!autoClose && !disabled && !displayQualification) {
          this.step = step
        }

        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        this.formData[key].status.answer = matchingInspectedComponent.findInspectionParameterByOrder(1).assessment.id
        this.formData[key].cost = matchingInspectedComponent.cost
        this.formData[key].comment = matchingInspectedComponent.comment
      }
    }

    for (const value of equipment.present) {
      const index = equipment.present.indexOf(value)
      await handleComponentChange(value, index + 2, value.value)
    }
    if (this.step > equipment.present.length && !displayQualification) {
      await this.loadExtraInfo()
    }

    if (displayQualification) {
      await this.loadExtraInfo()
    }
  }

  async loadExtraInfo () {
    const { idProcessInspection, inspection, aestheticEquipment } = this
    this.attributes = await this.getDealAutoAttribute(aestheticEquipment.id, idProcessInspection, inspection.id)
    this.extra = this.attributes?.filter(att => !this.exclude.includes(att.val)).map(att => att.componentValue.id)

    if (this.extra?.length) {
      this.displayFields()
    }
  }

  processFormData (field, order, component) {
    const param = component.findInspectionParameterByOrder(order)
    this.formData[field].answer = param?.assessment?.id
  }

  displayFields () {
    const { extra, equipment } = this
    this.readyExtra = false
    if (!extra?.length) return null

    this.extraEquipment = equipment.extra.filter(item => extra.includes(item.id))
    const keys = Object.keys(this.formDataExtra)

    if (keys.length) {
      this.extraBackup = _.cloneDeep(this.formDataExtra)
    }

    this.formDataExtra = {}

    for (const field of this.extraEquipment) {
      const backupData = this.extraBackup[field.value] || {
        status: {
          answer: null,
        },
        cost: 0,
        comment: '',
        photo: [],
      }

      this.$set(this.formDataExtra, field.value, backupData)

      this.$set(this.errorIdentifierMessageExtra, field.value, {
        status: '',
      })
    }

    this.readyExtra = true
  }

  @Watch('readyExtra', { immediate: true })
  @Debounce(100)
  async onReadyExtraChange (val) {
    if (!val) return

    const { idProcess, inspection, extraEquipment, displayQualification } = this

    const handleComponentChange = async (component, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        this.formDataExtra[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        this.formDataExtra[key].status.answer = matchingInspectedComponent?.findInspectionParameterByOrder(1)?.assessment?.id
        const option = this.getQuestionOptionScoreZero(component, 1)[0].id === this.formDataExtra[key].status.answer ? 'yes' : 'no'

        this.toggleIcon(component, key, option)
        this.formDataExtra[key].cost = matchingInspectedComponent.cost
        this.formDataExtra[key].comment = matchingInspectedComponent.comment
      }
    }

    for (const value of extraEquipment) {
      await handleComponentChange(value, value.value)
    }
    const last = extraEquipment?.length - 1
    if (this.formDataExtra?.[extraEquipment?.[last]?.value]?.status.answer && !displayQualification) {
      this.step = this.step + 1
    }
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  get isLinear () {
    const { isMetadata, inspection, categoryName } = this
    return isMetadata && !inspection.metadata.findCategoryByName(categoryName).done
  }

  get scoreStar () {
    const { inspection, categoryName } = this
    return inspection.metadata.findCategoryByName(categoryName).score
  }

  get displayRating () {
    const { categoryName, inspection } = this

    const score = inspection?.metadata?.findCategoryByName(categoryName)?.score

    return score && score >= 0
  }
  }
